@import '../../base/pages/authentication.scss';

.auth-wrapper .auth-footer-btn .btn {
  padding: 0.555rem 0.6rem !important;
}

.brand-logo img {
    max-width: 96px;
}

.auth-wrapper .brand-logo .brand-text {
    font-size: 48px;
    padding-top: 20px;
}