
#filterText {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
    border-color:lightgrey;
}
#filterText:focus {
    box-shadow: none;
    border-color:lightgrey;
}

#filterSelect {
    border-radius: 0 0.357rem 0.357rem 0;
    border-left: none;
    border-color:lightgrey;
}
#filterSelect:focus {
    box-shadow: none;
    border-color:lightgrey;
}
